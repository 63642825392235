import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Full Steam Ahead`}</h1>
    <p>{`Today is a big day for `}<a parentName="p" {...{
        "href": "/"
      }}>{`Mbanq Labs`}</a>{` and we all are very excited about it.`}</p>
    <p>{`Today we are officially launching our first batch. We're very happy about all the
`}<a parentName="p" {...{
        "href": "/mentors"
      }}>{`mentors`}</a>{` and `}<a parentName="p" {...{
        "href": "/partners"
      }}>{`partners`}</a>{` that joined us and we are looking forward working with
them.`}</p>
    <p>{`But we are even more excited about the startups that decided to join our first
batch and we are convinced that we will help them to reach their true
potential.`}</p>
    <p>{`We are very happy that we could win the startups from all around the world: US, Europe and Southeast Asia.`}</p>
    <p>{`It really strengthen  our plan of building a global network of accelerators to help the startups to achieve success on the bigger international scale.`}</p>
    <p>{`In the next days we will write about every startup and all the founders that joined us - stay tuned.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      